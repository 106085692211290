.ContentSec {
    padding: 100px 0;
}

.ContentMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px
}

.ContentSec .subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: start;
    letter-spacing: -0.01em;
    font-family: "Inter", sans-serif;
    color: #184a88;
}

.ContentSec h2 {
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #0c2340;
}

.pointsDiv {
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
    justify-content: start;
}

.pointsDiv h4 {
    text-align: start;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.03em;
    color: #0c2340;
}

.points {
    flex: 0 0 40%;
}

.points p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
}

.ContentImg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    height: 450px;
}

@media only screen and (min-width: 1200px) and (max-width: 1578px) {
    .ContentSec {
        padding: 90px 0;
    }

    .ContentSec .subtitle {
        font-size: 20px;
        line-height: 32px;
    }

    .ContentSec h2 {
        font-size: 50px;
        line-height: 60px;
    }

    .pointsDiv h4 {
        text-align: start;
    }

    .points p {
        font-size: 16px;
        line-height: 24px;
    }

    .ContentImg {
        width: auto;
        height: 450px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ContentSec {
        padding: 80px 0;
    }

    .ContentSec .subtitle {
        font-size: 20px;
        line-height: 32px;
    }

    .ContentSec h2 {
        font-size: 40px;
        line-height: 50px;
    }

    .pointsDiv h4 {
        font-size: 22px;
        line-height: 32px;
    }

    .points p {
        font-size: 14px;
        line-height: 20px;
    }

    .ContentImg {
        width: auto;
        height: 400px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ContentSec {
        padding: 80px 0;
    }

    .ContentMain {
        gap: 0
    }

    .ContentSec .subtitle {
        font-size: 18px;
        line-height: 30px;
    }

    .ContentSec h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .ContentSec p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    .pointsDiv {
        gap: 5px;
    }

    .pointsDiv h4 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;
    }

    .points {
        flex: 0 0 50%;
    }

    .points p {
        font-size: 14px;
        line-height: 20px;

    }

    .ContentImg {
        width: auto;
        height: 400px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .ContentSec {
        padding: 50px 0;
    }

    .ContentMain {
        gap: 0;
        flex-direction: column;
    }

    .ContentSec .subtitle {
        font-size: 16px;
        line-height: 28px;
    }

    .ContentSec h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .ContentSec p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    .pointsDiv {
        gap: 5px;
    }

    .pointsDiv h4 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;
    }

    .points {
        flex: 0 0 50%;
    }

    .points p {
        font-size: 14px;
        line-height: 20px;

    }

    .ContentImg {
        width: auto;
        height: 300px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .ContentSec {
        padding: 20px 0;
    }

    .ContentMain {
        gap: 0;
        padding: 0 10px;
        flex-direction: column;
    }

    .ContentSec .subtitle {
        font-size: 18px;
        line-height: 30px;
    }

    .ContentSec h2 {
        font-size: 25px;
        line-height: 33px;
    }

    .ContentSec p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    .pointsDiv {
        gap: 5px;
    }

    .pointsDiv h4 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;
    }

    .points {
        flex: 0 0 50%;
    }

    .points p {
        font-size: 14px;
        line-height: 20px;

    }

    .ContentImg {
        width: auto;
        max-height: 250px;
        margin-top: 20px;
    }
}