.FaqSec {
   padding: 80px 0;
}

.card {
   border: 1px solid #ddd;
   border-radius: 5px;
   margin-bottom: 15px;
   overflow: hidden;
   padding: 0;
}

.card:last-child {
   margin-bottom: 0;
}

.cardToggle {
   display: flex;
   align-items: center;
   cursor: pointer;
   justify-content: space-between;
   background-color: #f1f3f7;
   transition: 0.3s ease-in;
   padding: 10px 15px;
   width: 100%;
}

.cardToggle h5 {
   margin: auto 0;
}

.cardToggle .active {
   transition: 0.3s ease-out;
}

.cardToggle h5 {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 26px;
   color: #0c2340;
}

.cardToggleBody {
   flex: 1 1 auto;
   min-height: 1px;
   padding: 15px;
}

.cardToggleBody p {
   font-family: 'Poppins', sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1578px) {
   .FaqSec {
      padding: 80px 0;
   }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
   .FaqSec {
      padding: 70px 0;
   }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
   .FaqSec {
      padding: 70px 0;
   }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
   .FaqSec {
      padding: 40px 10px;
   }
}

@media only screen and (max-width: 575px) {
   .FaqSec {
      padding: 20px 10px;
   }
}

/* @media only screen and (min-width: 1200px) and (max-width: 1578px) {}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (min-width: 768px) and (max-width: 991px) {}

@media only screen and (min-width: 576px) and (max-width: 767px) {}

@media only screen and (max-width: 575px) {} */