.ServiceSec {
    padding: 80px 0;
}

.ServiceMain {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.ServiceMain .subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: start;
    letter-spacing: -0.01em;
    font-family: "Inter", sans-serif;
    color: #184a88;
    text-align: center;
}

.ServiceMain h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #0c2340;
}

.embla {
    max-width: 100%;
    padding-top: 35px;
    margin: 0;
    --slide-height: 19rem;
    --slide-spacing: 15px;
    /* --slide-size: 100%;
    --slide-spacing-sm: 1.7rem;
    --slide-size-sm: 50%;
    --slide-spacing-lg: 2rem; */
    --slide-size-lg: calc(100% / 3);
}

.emblaViewport {
    overflow: hidden;
}

.emblaContainer {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    /* margin-left: calc(var(--slide-spacing) * -1); */
}

.emblaSlide {
    /* min-width: 0; */
    flex: 0 0 33.33%;
    padding-left: var(--slide-spacing);
    /* margin-left: 5px;
    margin-right: 5px; */
}

.card {
    border-radius: 25px;
    border: 1px solid #B1B5C3;
}

.CardImg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    height: 250px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border: 'none'
}

.CardContent {
    display: flex;
    padding: 35px 25px;
    justify-content: space-between;
    text-align: start;
}

.CardContent h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #0c2340;
    text-align: start;
}



.CardContent .Arrow {
    border-radius: 25px;
    border: 1px solid #B1B5C3;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (min-width: 1200px) and (max-width: 1578px) {
    .ServiceSec {
        padding: 60px 0;
    }

    .ServiceMain .subtitle {
        font-size: 20px;
        line-height: 32px;
    }

    .CardImg {
        max-height: 240px;
    }

    .CardContent {
        padding: 30px 20px;
        ;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ServiceSec {
        padding: 40px 0;
    }

    .ServiceMain .subtitle {
        font-size: 20px;
        line-height: 32px;
    }


    .ServiceMain h2 {
        font-size: 35px;
        line-height: 45px;
    }

    .CardImg {
        max-height: 200px;
    }

    .CardContent {
        padding: 30px 20px;
    }

    .CardContent h4 {
        font-size: 16px;
        line-height: 24px;
    }

    .CardContent .Arrow {
        width: 42px;
        height: 35px;
    }

    p {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ServiceSec {
        padding: 40px 0;
    }

    .ServiceMain .subtitle {
        font-size: 18px;
        line-height: 30px;
    }


    .ServiceMain h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .embla {
        padding-top: 25px;
        --slide-spacing: 5px;
    }

    .CardImg {
        max-height: 170px;
    }

    .CardContent {
        padding: 20px 10px;
    }

    .CardContent h4 {
        font-size: 14px;
        line-height: 22px;
    }

    .CardContent .Arrow {
        width: 30px;
        height: 25px;
    }

    .CardContent p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .ServiceSec {
        padding: 40px 0;
    }

    .ServiceMain .subtitle {
        font-size: 16px;
        line-height: 28px;
    }


    .ServiceMain h2 {
        font-size: 25px;
        line-height: 35px;
    }

    .embla {
        padding: 25px 0 0 0;
        --slide-spacing: 10px;
        /* --slide-size-lg: calc(100% / 1); */
    }

    .emblaSlide {
        /* min-width: 0; */
        flex: 0 0 55%;
    }

    .CardImg {
        max-height: 170px;
    }

    .CardContent {
        padding: 20px 10px;
    }

    .CardContent h4 {
        font-size: 14px;
        line-height: 22px;
    }

    .CardContent .Arrow {
        width: 30px;
        height: 30px;
    }

    .CardContent p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 575px) {

    .ServiceSec {
        padding: 40px 0;
    }

    .ServiceMain .subtitle {
        font-size: 18px;
        line-height: 30px;
    }


    .ServiceMain h2 {
        font-size: 25px;
        line-height: 35px;
    }

    .embla {
        padding: 25px 0 0 0;
        --slide-spacing: 10px;
        /* --slide-size-lg: calc(100% / 1); */
    }

    .emblaSlide {
        /* min-width: 0; */
        flex: 0 0 100%;
    }

    .CardImg {
        max-height: 220px;
    }

    .CardContent {
        padding: 20px 10px;
    }

    .CardContent h4 {
        font-size: 18px;
        line-height: 26px;
    }

    .CardContent .Arrow {
        width: 35px;
        height: 35px;
    }

    .CardContent p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 10px;
    }

}