#root {
	text-size-adjust: 100%;
	letter-spacing: normal;
	background-color: #f8f4ec ;
	margin: 0 auto;
	width: 100%;
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Inter", sans-serif;
	font-style: normal;
}

h2 {
	font-weight: 600;
	font-size: 70px;
	line-height: 80px;
	letter-spacing: -0.02em;
}

.subtitle {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;
	text-align: start;
	letter-spacing: -0.01em;
}

p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.offcanvas-backdrop {
	display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1578px) {
	p {
		font-size: 16px;
		line-height: 24px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	p {
		font-size: 14px;
		line-height: 22px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	p {
		font-size: 14px;
		line-height: 22px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	p {
		font-size: 12px;
		line-height: 20px;
	}
}

@media only screen and (max-width: 575px) {
	p {
		font-size: 14px;
		line-height: 22px;
	}
}

/* @media only screen and (max-width: 1200px)  and (min-width: 1578PX) {
	
}

@media only screen and (max-width: 992px) and (min-width: 1199PX)  {
	
}

@media only screen and (max-width: 768px) and (min-width: 991PX)  {
	
}

@media only screen and (max-width: 576px) and (min-width: 767PX)  {
	
}

@media only screen and (max-width: 0px) and (min-width: 575px)  {
	
} */