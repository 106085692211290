.AboutSec {
    margin-top: 35px;
}

/* .AboutMain {} */

.aboutBanner {
    background-position: auto center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutBanner::before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(30, 41, 59, 0.8);
}

.aboutBannerContent {
    position: absolute;
}

.aboutBannerContent .subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: start;
    letter-spacing: -0.01em;
    font-family: "Inter", sans-serif;
    color: #fff;
    text-align: center;
}

.aboutBannerContent h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
}

.aboutBannerContent p {
    color: #fff;
    text-align: center;
}

/* .aboutMain {
    margin: auto;
    margin-top: -55px;
    z-index: 1;
    background: transparent !important;
    justify-content: center;
    gap: 15px;
} */

.about {
    margin: auto;
    margin-top: -55px;
    z-index: 1;
    background: transparent !important;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 15px;
}

.aboutMain {
    background: #fff;
    border-radius: 5px;
    padding: 25px 35px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 55px;
}


.aboutMainBG {
    border: 1px solid #ccc;
    padding: 25px;
    border-radius: 5px;
}

.aboutImg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* width: 100%;*/
    height: 450px;
    border-radius: 5px;
}

.aboutContent {
    padding: 0 25px;
}

.aboutContent h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 34px;
    color: #0c2340;
    text-align: start;
    text-transform: capitalize;
}

.aboutContent p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.info {
    display: flex;
}

.info svg {
    height: 25px;
}

.info h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0c2340;
    text-align: start;
}