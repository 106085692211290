.bannerSec {
  padding: 100px 0 20px 0;

}

.embla {
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;

}

.emblaViewport {
  overflow: hidden;

}

.emblaContainer {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);

}

.emblaSlide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.emblaControls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}

.emblaDots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.emblaDot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.emblaDot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}

.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}

.bannerMain {
  padding: 100px 0;
}


.bannerMain h1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -0.02em;
  color: #0c2340;
}

.BannerImg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: auto;
  height: 450px;
}

@media only screen and (min-width: 1200px) and (max-width: 1578px) {
  .bannerSec {
    padding: 100px 0 20px 0;

  }

  .bannerMain h1 {
    font-size: 60px;
  line-height: 70px;
  }

  .BannerImg {
    height: 450px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bannerSec {
    padding: 70px 0 15px 0;
  }

  .bannerMain h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .BannerImg {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bannerSec {
    padding: 50px 0 5px 0;
  }

  .bannerMain h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .BannerImg {
    height: 350px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bannerSec {
    padding: 20px 0 5px 0;
  }

  .bannerMain {
    padding: 100px 0 50px 0;
  }

  .bannerMain h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .BannerImg {
    height: 300px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .bannerSec {
    padding: 20px 0 5px 0;
  }



  .bannerMain {
    padding: 80px 10px 20px 10px;
  }

  .bannerMain h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .BannerImg {
    max-height: 250px;
    margin-top: 20px;
  }
}