/* Header styles */
.headerDiv {
    /* background: #E6E8EC; */
    /* background: rgb(230, 242, 255); */
    background: #fff;
    padding: 12px 0;
}

.headerLogo {
    width: auto;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerItem {
    color: #777E91;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    margin: auto 15px;
}

.headerItem:hover {
    color: #0c2340;
    font-weight: 500;
}

.headerLogo2 {
    width: auto;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerItem2 {
    color: #777E91;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-decoration: none;
    margin: auto 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.headerItem2:hover {
    color: #0c2340;
    font-weight: 500;
}

.active {
    color: #0c2340;
    font-weight: 500;
}

.HeaderBtn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 00;
    font-size: 14px;
    line-height: 16px;
}



.Toggle {
    display: flex;
    flex-direction: column;
    width: 23px;
    cursor: pointer;
}

.toggleLine {
    background: #0c2340;
    border-radius: 10px;
    height: 2px;
    margin: 2px 0;
    transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

}

.Toggle .toggleLine:nth-of-type(1) {
    width: 50%;

}

.Toggle .toggleLine:nth-of-type(2) {
    width: 100%;
}


.Toggle .toggleLine:nth-of-type(3) {
    width: 75%;

}


.toggleActive .toggleLine:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(2px, 0px)
}


.toggleActive .toggleLine:nth-of-type(2) {

    transform-origin: top;
    transform: rotatez(-45deg)
}


.toggleActive .toggleLine:nth-of-type(3) {

    transform-origin: bottom;
    width: 50%;
    transform: translate(9px, -3px) rotatez(45deg);

}

.sideBar {
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: 320px !important;

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .headerLogo {
        height: 40px;
    }

    .sideBar {
        width: 350px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .headerLogo {
        height: 35px;
    }

    .sideBar {
        width: 300px !important;
    }
}

@media only screen and (max-width: 575px) {
    .headerLogo {
        height: 30px;
    }

    .sideBar {
        width: 250px !important;
    }
}